/*!
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

form.supplier-form {
  margin-left: 20px;

  label {
    min-width: 500px;
    max-width: 500px;
    font-size: 0.8em;
    display: block;
  }

  input {
    min-width: 300px;
    max-width: 300px;
    width: auto;
    animation-duration: 0.1ms;
    animation-name: mui-node-inserted;
    display: block;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.26);
    outline: 0;
    width: 100%;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background-image: none;
  }

  input[type='checkbox'] {
    position: absolute;
    background: #fff;
    display: block;
    margin-left: -20px;
    margin-top: 4px;
  }

  button {
    margin-top: 20px;
    margin-left: 0px;
    border: none;
    color: #000;
    background-color: #fff;
    animation-duration: 0.1ms;
    animation-name: mui-node-inserted;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    height: 36px;
    padding: 0 26px;
    margin-top: 6px;
    margin-bottom: 6px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-image: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    letter-spacing: 0.03em;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
  }

  button.primary {
    color: #fff;
    background-color: #39a1f4;
  }

  .form-row {
    margin-bottom: 5px;
  }

  .button-panel {
    margin-top: 20px;
  }
}

.mui-btn--dark {
  background: #2f2f2f;
}

.mui-btn__ripple-container {
  font-size: 12px;
}
