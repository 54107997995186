/*!
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

.organization-row {
  div {
    margin: 2px;
    margin-bottom: 5px;
    font-size: 0.9em;
  }

  .col-1-6 {
    width: 18%;
    max-width: 18%;
    min-width: 18%;
    display: inline-block;
  }

  .col-icon {
    float: right;
    margin-right: 10px;
    width: auto;
    display: inline-block;
  }

  .organization-header {
    font-weight: 600;
    text-transform: uppercase;
    background: rgba(238, 238, 238, 0.28);
    margin-bottom: 5px;
  }
}

.organization-row-item:nth-child(2n) {
  background: hsla(0, 0%, 50%, 0.07);
}
