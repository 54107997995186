/*!
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

.responsibility-row {
  div {
    margin: 2px;
    margin-bottom: 5px;
    font-size: 0.9em;
  }

  [class*='col-'] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .col-1-3 {
    width: 53%;
    max-width: 53%;
    min-width: 53%;
    display: inline-block;
  }

  .col-1-5 {
    width: 18%;
    max-width: 18%;
    min-width: 18%;
    display: inline-block;
  }

  .col-1-4 {
    width: 25%;
    max-width: 25%;
    min-width: 25%;
    display: inline-block;
  }

  .col-1-6 {
    width: 14%;
    max-width: 14%;
    min-width: 14%;
    display: inline-block;
  }

  .col-1-7 {
    width: 10%;
    max-width: 10%;
    min-width: 10%;
    display: inline-block;
  }

  .expandable {
    color: #366dab;
    font-weight: 600;
    cursor: pointer;
  }

  .col-icon {
    margin-right: 5px;
    float: right;
    width: auto;
    display: inline-block;
  }

  .responsibility-header {
    font-weight: 600;
    text-transform: uppercase;
    background: rgba(238, 238, 238, 0.28);
    margin-bottom: 5px;
  }

  .resp-row-item:nth-child(2n) {
    background: hsla(0, 0%, 50%, 0.07);
  }
  .resp-row-item {
    display: flex;
  }
}

.li:after {
  content: '';
  display: block;
  clear: both;
}
