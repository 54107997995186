.poi-filter-editor {
  .poi-filter-editor-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    :not(:last-child) {
      margin-left: 7px;
    }
  }
}
